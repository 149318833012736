@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Caladea:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Space Mono', sans-serif;
}

* {
  box-sizing: border-box;
  font-family: 'Space Mono', sans-serif;
}

@keyframes blink-cursor {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}

.font-sans, .font-sans * {
  font-family: 'Noto Sans', sans-serif;
}

.font-mono, .font-mono * {
  font-family: 'Space Mono', sans-serif;
}

.dark-gradient {
  background: linear-gradient(135deg, #040d14, #0d1f2d, #1c3a4a, #1c3c57, #091b2d);
  background-size: cover;
  background-attachment: fixed;
}

.dark-gradient .service-card-container .p-card {
  background-color: #0d1f2d;
  color: #fff;
}

.dark-gradient .service-card-container .p-card .highlight {
  background-color: #1c3a4a;
}

.service-card-container .p-card {
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
}

.service-card-container .p-card img {
  border-top-left-radius: 1rem;
}

.service-card-container .p-card .p-card-body {
  line-height: 1.5;
}

.highlight {
  background-color: #e6f1ff;
}